import * as React from "react";
import { Link } from "gatsby";
import { Layout } from "../components/Layout";
import styled, { css } from "styled-components";
import a11yicon from "../assets/images/bitesizeda11y.svg";
import Twitter from "../images/twitter.png";

const Icon = styled.img`
  height: 100px;
  width: 100px;
`;

const BrandLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BrandLink = styled.a`
  display: block;
  font-family: "Gilroy", sans-serif;
  font-size: 1.2rem;
  color: var(--highlight);
  text-decoration: none;
  margin: 20px auto 40px;
  text-align: center;
  flex: 1 0 auto;
  overflow: hidden;
  font-weight: bold;
  padding: 5px;
`;

const H1 = styled.h1`
  font-size: 3.5rem;
  margin: 2rem auto 2.5rem;
`;

const CTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TwitterCTA = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  font-size: 1.2rem;
  background: #1da1f2;
  padding: 0.875rem 1.125rem;
  color: #ffffff;
  border-radius: 4px;
  width: max-content;

  &:hover {
    text-decoration: none;
  }
`;

const TwitterIcon = styled.img`
  height: 1.125rem;
  margin-right: 8px;
`;

const Highlight = styled.em`
  color: var(--purple);
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const ThankYouPage = () => {
  return (
    <main>
      <Layout>
        <BrandLinkWrapper>
          <BrandLink href="/">a11y examples</BrandLink>
        </BrandLinkWrapper>
        <Icon
          src={a11yicon}
          alt="a11y examples logo with a bite taken out of it"
        />
        <H1>You're awesome, thanks for signing up!</H1>
        <p>
          We're really looking forward to sharing our accessibility knowledge
          with you.
        </p>
        <p>
          By the way, if you think your friends, colleagues or followers would
          benefit from the "Bitesized web accessibility" course, would you mind
          sharing this?
        </p>
        <CTAWrapper>
          <TwitterCTA
            href="https://twitter.com/intent/tweet?text=I%20just%20signed%20up%20for%20this%20awesome%20accessibility%20course%20%F0%9F%99%8C&url=https://a11yexamples.com/?ref=twitter&via=CormacChisholm&hashtags=a11y,accessibility,wcag"
            target="_blank"
          >
            <TwitterIcon src={Twitter} alt="Twitter Logo" />
            Click here to tweet
          </TwitterCTA>
          {/* <p>
            <Link to="/">Back to home</Link>
          </p> */}
        </CTAWrapper>
      </Layout>
    </main>
  );
};

export default ThankYouPage;
